<template>
  <land-section
    id="my_issue_video"
    space="40"
    color="grey lighten-4"
  >
    <v-container
      style="max-width: 900px;"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-card
            class="pa-14 rounded"
            color="white"
            flat
            tile
          >
            <span class="text-h5">提交视频参赛作品</span>
            <div class="co-w-full co-h16 co-border-b co-border-gray-300" />
            <div class="co-w-full co-h40" />
            <el-upload
              v-if="wdForm.state === 'issue'"
              :ref="wdVideo.name"
              :action="wdVideo.action"
              :accept="wdVideo.accept"
              :headers="wdVideo.headers"
              :limit="wdVideo.limit"
              :file-list="wdVideo.files"
              :auto-upload="wdVideo.autoUpload"
              :on-exceed="onVideoExceed"
              :on-success="onVideoSuccess"
              :on-change="onVideoChange"
              :on-remove="onVideoRemove"
              :before-remove="onVideoBeforeRemove"
              :before-upload="onVideoBeforeUpload"
              class="video-uploader"
            >
              <el-button
                slot="trigger"
                size="small"
                type="primary"
                :disabled="btnSelect.disabled"
              >
                {{ btnSelect.text }}
              </el-button>
              <el-button
                v-if="btnUpload.visible"
                size="small"
                type="success"
                :disabled="btnUpload.disabled"
                :loading="btnUpload.loading"
                style="margin-left: 16px;"
                @click="onVideoUpload"
              >
                {{ btnUpload.text }}
              </el-button>
              <div
                slot="tip"
                class="co-text-xs blue-grey--text mt-2"
              >
                {{ wdVideo.tip }}
              </div>
            </el-upload>
            <div class="co-w-full co-h32" />
            <el-form
              v-if="wdForm.state === 'issue'"
              ref="frmVideo"
              :model="wdForm"
              :rules="wdRule"
              label-width="100px"
              label-position="top"
            >
              <el-form-item
                label="标题"
                prop="title"
              >
                <el-input
                  v-model="wdForm.title"
                  maxlength="64"
                  show-word-limit
                />
              </el-form-item>
              <el-form-item
                :label="txtTags.label"
                :prop="txtTags.name"
              >
                <el-select
                  v-model="txtTags.value"
                  placeholder="选择标签"
                  style="width: 320px;"
                >
                  <el-option
                    v-for="tag in txtTags.items"
                    :key="tag"
                    :label="tag"
                    :value="tag"
                  />
                </el-select>
              </el-form-item>
              <div
                class="co-flex-col my-2"
              >
                <span
                  class="co-text-sm co-text-gray-700 mb-3"
                >
                  <span class="red--text">*</span>
                  封面
                </span>
                <el-upload
                  :ref="wdCover.name"
                  :action="wdCover.action"
                  :accept="wdCover.accept"
                  :headers="wdCover.headers"
                  :limit="wdCover.limit"
                  :file-list="wdCover.files"
                  :auto-upload="wdCover.autoUpload"
                  :show-file-list="false"
                  :before-upload="onCoverBeforeUpload"
                  :on-success="onCoverSuccess"
                  class="cover-uploader"
                >
                  <img
                    v-if="wdCover.value"
                    :src="wdCover.value"
                    class="cover-video"
                  >
                  <i
                    v-else
                    class="el-icon-plus cover-uploader-icon"
                  />
                </el-upload>
                <div
                  class="co-text-xs blue-grey--text mb-4"
                >
                  {{ wdCover.tip }}
                </div>
              </div>
              <el-form-item
                label="分享内容"
                prop="description"
              >
                <el-input
                  v-model="wdForm.description"
                  type="textarea"
                  maxlength="128"
                  rows="4"
                  show-word-limit
                />
              </el-form-item>
              <el-form-item>
                <div class="co-flex-col">
                  <span class="blue-grey--text">
                    {{ tipIdentity }}
                  </span>
                  <div class="co-flex-row co-justify-between co-items-center">
                    <el-button
                      :loading="btnIssue.loading"
                      :disabled="!allowed"
                      type="primary"
                      style="width: 200px;"
                      @click="onSubmit"
                    >
                      {{ btnIssue.text }}
                    </el-button>
                    <div
                      v-if="imageable || articlable"
                      class="co-flex-row co-items-center"
                    >
                      <el-button
                        v-if="imageable"
                        plain
                        @click="toIssueImage"
                      >
                        {{ btnIssueImg.text }}
                      </el-button>
                      <el-button
                        v-if="articlable"
                        plain
                        @click="toIssueArticle"
                      >
                        {{ btnIssueArt.text }}
                      </el-button>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </el-form>
            <div
              v-if="wdForm.state !== 'issue'"
              class="co-flex-col co-justify-center co-items-center"
            >
              <v-icon
                size="160"
                :color="resultTip.color || 'grey'"
              >
                {{ resultTip.icon }}
              </v-icon>
              <span
                class="co-text-md blue-grey--text lighten-2 mt-4"
              >
                {{ resultTip.hint }}
              </span>
              <v-btn
                dark
                width="180"
                height="40"
                :color="resultTip.color"
                class="mt-8"
                @click="onNext"
              >
                {{ resultTip.next }}
              </v-btn>
              <div class="co-w-full co-h120" />
              <div
                v-if="imageable || articlable"
                class="co-flex-row co-justify-end co-items-center co-w-full"
              >
                <el-button
                  v-if="imageable"
                  plain
                  @click="toIssueImage"
                >
                  {{ btnIssueImg.text }}
                </el-button>
                <el-button
                  v-if="articlable"
                  plain
                  @click="toIssueArticle"
                >
                  {{ btnIssueArt.text }}
                </el-button>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <dialog-alert ref="dlgAlert" />
  </land-section>
</template>

<script>
  import api from '@/api/co.api'
  import web from '@/api/web/co.web'
  import user from '@/api/co.user'

  export default {
    components: {
      DialogAlert: () => import('@/pages/sections/comm/DialogAlert.vue')
    },
    metaInfo: { title: '上传参赛作品' },
    props: {
      params: {
        type: Object,
        default: () => {
          return {}
        }
      },
      userInfo: {
        type: Object,
        default: () => {
          return {}
        }
      },
      imageable: {
        type: Boolean,
        default: false
      },
      articlable: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        wdVideo: {
          name: 'upload_video',
          action: `${api.url.web_util_file_upload}?folder=video&type=video`,
          accept: '.mp4,.avi,.mov,.rmvb,.rm',
          autoUpload: true,
          tip: '只能上传mp4/avi/mov/rmvb文件，且视频文件不能超过500MB(选择视频后会自动上传)。',
          headers: {},
          limit: 1,
          value: '',
          files: [],
          items: []
        },
        wdCover: {
          name: 'upload_cover',
          action: `${api.url.web_util_file_upload}?folder=image`,
          accept: '.jpg,.jpeg,.png,.gif',
          autoUpload: true,
          tip: '只能上传jpg/png/gif文件，且图片不能超过5MB，建议比例为16:9',
          headers: {},
          limit: 1,
          value: '',
          files: []
        },
        txtTags: {
          label: '作品所属标签',
          name: 'activityTag',
          value: '',
          items: [],
        },
        tagRules: [
          { required: true, message: '必须选择所属标签', trigger: 'blur' },
        ],
        btnSelect: {
          disabled: false,
          text: '选择视频文件'
        },
        btnUpload: {
          disabled: true,
          loading: false,
          visible: false,
          text: '上传视频到服务器'
        },
        btnIssue: {
          loading: false,
          text: '提交视频作品'
        },
        btnIssueImg: {
          disabled: false,
          text: '提交图片作品'
        },
        btnIssueArt: {
          disabled: false,
          text: '提交图文作品'
        },
        wdForm: {
          state: 'issue',
          title: '',
          description: '',
          accessScope: '',
        },
        wdRule: {
          title: [
            { required: true, message: '请输入发表视频标题', trigger: 'blur' },
            { min: 2, max: 64, message: '长度在 2 到 64 个字符', trigger: 'blur' }
          ]
        },
        allowIdentitys: [
          '医学生',
          '医务工作者'
        ],
        tipIdentity: '仅限于医学生及医务工作者投稿',
        allowed: true,
        tips: {
          success: {
            value: 'success',
            icon: 'mdi-check-circle-outline',
            color: 'success',
            hint: '视频作品提交成功',
            next: '返回活动页面',
            handler: this.toBack
          },
          error: {
            value: 'error',
            icon: 'mdi-close-circle-outline',
            color: 'error',
            hint: '视频作品提交失败',
            next: '重新提交',
            handler: this.toReissue
          }
        },
        resultTip: {},
      }
    },
    watch: {
      params: {
        handler (val) {
          const temp = val.videoTags || ''
          this.txtTags.items = web.comm.parseTags(temp, false)

          if (this.txtTags.items.length > 0) {
            this.txtTags.value = this.txtTags.items[0]
            this.wdRule[this.txtTags.name] = this.tagRules
          } else {
            this.wdRule[this.txtTags.name] = []
          }
        },
        immediate: true
      },
      userInfo: {
        handler (val) {
          const identity = val.identity || ''
          if (this.allowIdentitys.indexOf(identity) !== -1) {
            this.allowed = true
          } else {
            this.allowed = false
          }
        },
        immediate: true
      }
    },
    created () {
      this.configHeaders()
    },
    methods: {
      showAlert (type, message) {
        const dlg = this.$refs.dlgAlert
        if (dlg) {
          dlg.show({
            type,
            message
          })
        }
      },
      setResultTip (val, message = '') {
        const tip = this.tips[val] || {}
        this.resultTip = Object.assign({}, tip)
        if (message) {
          this.resultTip.hint = `${tip.hint}(${message})`
        }
        this.wdForm.state = tip.value || 'issue'
      },
      toBack () {
        api.page.navigateBy(this, 'activityDetail', this.params)
      },
      toReissue () {
        this.issueContent()
      },
      toIssueImage () {
        this.$emit('change-mode', 'image')
      },
      toIssueArticle () {
        this.$emit('change-mode', 'article')
      },
      onVideoUpload () {
        this.uploadVideo()
      },
      configHeaders () {
        const obj = user.token.get()
        const headers = {
          token: obj.accessToken,
          uuid: obj.userId
        }
        this.wdVideo.headers = headers
        this.wdCover.headers = headers
      },
      setTitleWithName (fileName) {
        if (!fileName) {
          return
        }
        const src = api.comm.trim(this.wdForm.title)
        if (src) {
          return
        }

        const arr = fileName.split('.')
        const dst = arr[0] || ''
        this.wdForm.title = api.comm.trim(dst)
      },
      checkVideoSelect () {
        const cnt = this.wdVideo.items.length
        this.btnSelect.disabled = !!cnt
      },
      checkVideoUpload () {
        const cnt = this.wdVideo.items.length
        this.btnUpload.disabled = !cnt
        if (cnt) {
          const first = this.wdVideo.items[0]
          const resp = first.response || {}
          const data = resp.data || {}
          const url = data.fileUrl || ''
          this.wdVideo.value = url
          this.btnUpload.disabled = !!url
        }
      },
      checkVideoActions () {
        this.checkVideoSelect()
        this.checkVideoUpload()
      },
      checkWidgets () {
        let result = ''
        const arr = []
        if (this.wdVideo.items.length < 1) {
          arr.push('必须选择一个视频')
        } else {
          if (!this.wdVideo.value) {
            arr.push('必须将视频上传到服务器')
          }
        }

        if (!this.wdForm.title) {
          arr.push('必须填写标题')
        }

        // if (!this.wdCover.value) {
        //   arr.push('必须上传封面')
        // }

        result = arr.join('，')
        return result
      },
      uploadVideo () {
        const frm = this.$refs[this.wdVideo.name]
        if (frm) {
          frm.submit()
        }
      },
      onVideoRemove (file, fileList) {
        // console.log({ file, fileList })
        this.wdVideo.items = fileList
        this.checkVideoActions()
      },
      onVideoBeforeRemove (file, fileList) {
        return this.$confirm(`确定移除 ${ file.name }？`)
      },
      onVideoExceed (file, fileList) {
        console.log('onVideoExceed')
      },
      onVideoBeforeUpload (file) {
        // console.log('onVideoBeforeUpload')
        const isLt500M = file.size / 1024 / 1024 <= 500

        if (!isLt500M) {
          this.$notify({
            title: '错误提示',
            message: '上传视频文件大小不能超过 500MB!',
            type: 'error'
          })
        } else {
          this.btnUpload.loading = true
        }
        return isLt500M
      },
      onVideoSuccess (resp, file, fileList) {
        // console.log('onVideoSuccess')
        // console.log({ resp, file, fileList })
        this.btnUpload.loading = false
        if (resp.code === 200) {
          const data = resp.data || {}
          if (!this.wdCover.value) {
            this.wdCover.value = data.videoCover
          }
        }
      },
      onVideoChange (file, fileList) {
        // console.log('onVideoChange')
        // console.log({ file, fileList })
        this.wdVideo.items = fileList
        this.checkVideoActions()
        this.setTitleWithName(file.name)
      },
      onCoverSuccess (res, file) {
        const me = this
        console.log({ res, file })
        if (res.code === 200) {
          const resp = file.response || {}
          const data = resp.data || {}
          this.wdCover.value = data.fileUrl || ''
        } else if (res.code === 300) {
          this.$confirm('登录后才上传, 是否现在登录?', '登录提示', {
            confirmButtonText: '是',
            cancelButtonText: '否',
            type: 'info'
          }).then(() => {
            this.toLogin()
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消登录！'
            })
          })
        } else {
          const err = res.data || {}
          me.showAlert('error', `${err.code}-${err.desc}`)
        }
      },
      onCoverBeforeUpload (file) {
        const isLt2M = file.size / 1024 / 1024 <= 5

        if (!isLt2M) {
          this.$notify({
            title: '错误提示',
            message: '上传封面图片大小不能超过 5MB!',
            type: 'error'
          })
        }
        return isLt2M
      },
      toLogin () {
        const params = {
          mode: 'phone'
        }
        api.page.navigate(this, 'login', params)
      },
      getData () {
        const content = {
          path: this.wdVideo.value
        }

        const result = {
          activityId: this.params.activityId,
          userId: this.userInfo.userId,
          title: this.wdForm.title,
          activityTag: this.txtTags.value,
          description: this.wdForm.description,
          cover: this.wdCover.value,
          type: web.comm.ContentTypes.VIDEO,
          state: web.comm.EnrollStates.NOPASS,
          accessScope: web.comm.Scopes.PUBLIC,
          content: JSON.stringify(content)
        }
        return result
      },
      issueContent () {
        const me = this
        const data = this.getData()
        const executing = function () {
          me.btnIssue.loading = true
        }

        const executed = function (res) {
          me.btnIssue.loading = false
          if (res.status) {
            me.setResultTip('success')
            me.showAlert('success', '作品提交成功')
          } else {
            const message = `${res.data.code} - ${res.data.desc}`
            me.setResultTip('error', message)
            me.showAlert('error', message)
          }
        }

        web.activityEnroll.create({
          data,
          executing,
          executed
        })
      },
      onNext () {
        const handler = this.resultTip.handler
        if (typeof handler === 'function') {
          handler()
        }
      },
      checkIdentity () {
        let result = false
        const identity = this.userInfo.identity || ''
        if (this.allowIdentitys.indexOf(identity) !== -1) {
          result = true
        } else {
          result = false
        }
        return result
      },
      onSubmit () {
        if (!this.checkIdentity()) {
          this.showAlert('warning', this.tipIdentity)
          return
        }
        const message = this.checkWidgets()
        if (message) {
          this.$notify({
            title: '错误提示',
            message,
            type: 'error'
          })
          return
        }

        this.issueContent()
      }
    }
  }
</script>

<style>
  .video-uploader  a {
    color: #263238 !important;
    font-size: 16px !important;
  }

  .v-application ul {
    padding-left: 0 !important;
  }
  .v-application ol {
    padding-left: 0 !important;
  }

  .cover-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .cover-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .cover-uploader-icon {
    font-size: 32px;
    color: #8c939d;
    width: 320px;
    height: 180x;
    line-height: 180px;
    text-align: center;
  }
  .cover-video {
    width: 320px;
    height: 180x;
    display: block;
  }

  .el-form-item {
    margin-bottom: 26px !important;
  }

  .el-form-item__label {
    font-size: 16px !important;
    line-height: auto !important;
  }
</style>
